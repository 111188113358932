@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@300;700;800;900&family=Glory&family=Italianno&family=Karla:wght@200&family=Mukta:wght@300&family=Noto+Kufi+Arabic:wght@200&family=Outfit:wght@200&family=Overpass:wght@100&family=Poppins:wght@200&family=Quattrocento+Sans&family=Roboto&family=Tajawal&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: "Outfit", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app {
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
}

.search {
  text-align: center;
  padding: 1rem;
}

.search input {
  font-size: 1.2rem;
  padding: .7rem 1.5rem;
    border: 1px solid rgba(255, 255, 255, 0.8);
  background-color: rgba(255, 255, 255, 0.1);
  color: #f8f8f8;
  border-radius: 25px;
}

::placeholder {
  color: #f8f8f8;

}

p {
  font-size: 1.2rem;
}
h1 {
  font-size: 6rem;
}

.app::before {
  content: "";
  background: url(./assets/photo-1530908295418-a12e326966ba.avif) center
    center/cover;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

}

.container {
  width: 100%;
  height: 87vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.container .description {
  position: relative;
  right: -90%;
transform-origin: 0 0 ;
transform: rotate(269deg);
}

.bold {
  font-weight: bold;
}

.app .bottom{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 98%;
  margin: 1rem auto;
  padding: 1rem;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.2);

}